@import "fonts";

$fonts-bold: "bold";
$fonts-medium: "medium";
$fonts-regular: "regular";
$fonts-light: "light";
$fonts-medium-italic: "medium-italic";
$fonts-thin: "thin";
$hydro: #26ace2;
$dark-blue: #005EA3;
$light-blue: #E6EEF4;
$white: white;
$main-color: #152331;
$task-green: #25E083;
$task-green-light: #25e0826c;
$task-yellow: #F7E05D;
$main-background-color: #F5F7FA;
$table-header-text: #01102A;

$shadow-color: rgba(0, 0, 0, 0.589);
$shadow-tabs-olor: rgba(0, 52, 112, 0.15);
$text-color: #152331;
$light-text-color: #4D5D6E;
$red-border: #FF7590;
$light-red: #FFF5F7;

$input-border: #c5c5c5;
$input-text-color: rgba(0, 0, 0, 0.5);
$light-border-calendar: #A2BFD5;
$input-background: #F9FAFC; 

@mixin basicShadow {
  // box-shadow: 0px 0px 16px -10px $shadow-color;
  box-shadow: 0px 0px 10px -4.5px rgba(0, 0, 0, 0.3);  //equal on 4 sides
}

@mixin bottomShadowLight {
  box-shadow: 0px 3px 6px #00000017;
}

@mixin sectionShadow {
  box-shadow: 0px 5px 15px 0px rgba(0, 52, 112, 0.15);
}

@mixin bottomShadow {
  box-shadow: 0 5px 5px rgba(0, 52, 112, 0.15);
}

@mixin smallHeading {
  font-size: 1rem;
  font-family: $fonts-regular;
  color: $main-color;
}

@mixin smallerHeading {
  font-size: 0.8rem;
  font-family: $fonts-regular;
  color: #152331;
  margin: 0px;
}

@mixin smallestHeading {
  font-size: 16px;
  font-family: $fonts-medium;
  color: #152331;
  margin: 0px;
}

@mixin mediumHeading {
  font-size: 18px;
  font-family: $fonts-bold;
  color: #152331;
  margin: 0px;
}

@mixin largeHeading {
  font-size: 22px;
  font-family: $fonts-bold;
  color: #152331;
  margin: 0px;
}

@mixin tableHeaderElement {
  font-size: 14px;
  // font-size: 0.875rem;
  font-family: $fonts-bold;
  color: $table-header-text;
}

.text-blue {
  color: #26ace2;
}

h2 {
  @include mediumHeading;
}

h3 {
  @include smallHeading;
}

h4 {
  @include smallerHeading;
}

h5 {
  @include smallestHeading;
}

@mixin emptyComponent {
  font-size: 18px;
  font-family: $fonts-light;
  color: $text-color;
  text-align: center;
}

@mixin primaryBtn {
  padding: 0.8em 2em;
  background-color: $hydro;
  border-radius: 5px;
  border-style: none;
  color: white;
  cursor: pointer;
  margin: 1em;
  transition: 0.5s;
  box-sizing: border-box;
  margin: 0.5em;
  font-size: 1rem;
  box-shadow: 0px 0px 10px -4.5px $hydro;

  // &:hover {
  //   opacity: 75%;
  //   transition: 0.5s;
  // }
}

@mixin secondaryBtn {
  padding: 0.8em 2em;
  background-color: $white;
  border-style: none;
  margin: 0.5em;
  box-shadow: 0px 0px 10px -4.5px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  color: $text-color;
  cursor: pointer;
  transition: 0.5s;
  box-sizing: border-box;
  font-size: 1rem;
}

@mixin primaryBtnSmall {
  @include primaryBtn;
  // padding: 0.8em 1.5em;
  font-size: 0.9rem;
}

@mixin secondaryBtnSmall {
  @include secondaryBtn;
  // padding: 0.8em 1.5em;
  font-size: 0.9rem;
}

@mixin deleteBtn {
  @include secondaryBtn;
  background-color: white;
  border: 1px solid $red-border;
  font-size: 0.9rem;
}


@mixin flexCenter($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: $direction;
}

@mixin flexStart($direction) {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  flex-direction: $direction;
}

@mixin flex($direction) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-direction: $direction;
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
@mixin scrollbarThin() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin hiddenScrollbar() {
  scrollbar-color: transparent;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin scrollbarHorizontal() {
  scrollbar-color: #999;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 8px;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin scrollbarHorizontalThin() {
  scrollbar-color: #999;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 3px;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin taskContainer {
  @include flexStart(column);
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 24vw;
  // height: 100vh;
  margin: 0 0.5em;
  padding: 1em;
  border-radius: 5px;
  background-color: $main-background-color;
  border-left: 12px solid $task-green;
  @include basicShadow;
}

@mixin input {
  padding: 0.7em;
  margin-top: 1em;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid $input-border;

  &:active, &:focus {
    outline: none;
  }
}