@font-face {
  font-family: 'Montserrat-Black';
  src: url('fonts/Montserrat-Black.eot');
  src: url('fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Black.woff2') format('woff2'),
      url('fonts/Montserrat-Black.woff') format('woff'),
      url('fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: url('fonts/Montserrat-BlackItalic.eot');
  src: url('fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-BlackItalic.woff2') format('woff2'),
      url('fonts/Montserrat-BlackItalic.woff') format('woff'),
      url('fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('fonts/Montserrat-Light.eot');
  src: url('fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Light.woff2') format('woff2'),
      url('fonts/Montserrat-Light.woff') format('woff'),
      url('fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url('fonts/Montserrat-Italic.eot');
  src: url('fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Italic.woff2') format('woff2'),
      url('fonts/Montserrat-Italic.woff') format('woff'),
      url('fonts/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url('fonts/Montserrat-LightItalic.eot');
  src: url('fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-LightItalic.woff2') format('woff2'),
      url('fonts/Montserrat-LightItalic.woff') format('woff'),
      url('fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url('fonts/Montserrat-BoldItalic.eot');
  src: url('fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-BoldItalic.woff2') format('woff2'),
      url('fonts/Montserrat-BoldItalic.woff') format('woff'),
      url('fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}



@font-face {
  font-family: 'Montserrat-Bold';
  src: url('fonts/Montserrat-Bold.eot');
  src: url('fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Bold.woff2') format('woff2'),
      url('fonts/Montserrat-Bold.woff') format('woff'),
      url('fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('fonts/Montserrat-ExtraBold.eot');
  src: url('fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-ExtraBold.woff2') format('woff2'),
      url('fonts/Montserrat-ExtraBold.woff') format('woff'),
      url('fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-ExtraLightItalic';
  src: url('fonts/Montserrat-ExtraLightItalic.eot');
  src: url('fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
      url('fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
      url('fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('fonts/Montserrat-ExtraLight.eot');
  src: url('fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-ExtraLight.woff2') format('woff2'),
      url('fonts/Montserrat-ExtraLight.woff') format('woff'),
      url('fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('fonts/Montserrat-SemiBold.eot');
  src: url('fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-SemiBold.woff2') format('woff2'),
      url('fonts/Montserrat-SemiBold.woff') format('woff'),
      url('fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('fonts/Montserrat-Regular.eot');
  src: url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Regular.woff2') format('woff2'),
      url('fonts/Montserrat-Regular.woff') format('woff'),
      url('fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: url('fonts/Montserrat-SemiBoldItalic.eot');
  src: url('fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
      url('fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
      url('fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('fonts/Montserrat-Thin.eot');
  src: url('fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Thin.woff2') format('woff2'),
      url('fonts/Montserrat-Thin.woff') format('woff'),
      url('fonts/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-ThinItalic';
  src: url('fonts/Montserrat-ThinItalic.eot');
  src: url('fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-ThinItalic.woff2') format('woff2'),
      url('fonts/Montserrat-ThinItalic.woff') format('woff'),
      url('fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('fonts/Montserrat-Medium.eot');
  src: url('fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-Medium.woff2') format('woff2'),
      url('fonts/Montserrat-Medium.woff') format('woff'),
      url('fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url('fonts/Montserrat-MediumItalic.eot');
  src: url('fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Montserrat-MediumItalic.woff2') format('woff2'),
      url('fonts/Montserrat-MediumItalic.woff') format('woff'),
      url('fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

