*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}
body {
  color: black;
  overflow: hidden;
}
#root {
  min-height: 100vh;
  flex: 1;
  height: 100%;
}

.dashboard-wrap {
  height: 100vh;
  width: 100vw;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

@import url("https://fonts.googleapis.com/css?family=Permanent+Marker");
@import url("https://fonts.googleapis.com/css?family=PT+Sans");
