@import "./base.scss";

/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family-regular;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-bold;
}
a {
  color: $txt-color;
  @include transition($animation);
}
/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/
p {
  line-height: 1.5em;
}
p:last-child {
  padding-bottom: 0;
}

ul li {
  list-style: none;
}
ol {
  list-style-position: inside;
}

b,
.bold,
.font-bold {
  font-family: $font-family-bold;
}
.font-semiBold {
  font-family: $font-family-semiBold;
}
.font-medium {
  font-family: $font-family-medium;
}
.font-regular {
  font-family: $font-family-regular;
}
.font-black {
  font-family: $font-family-black;
}
.font-light {
  font-family: $font-family-light;
}
.font-thin {
  font-family: $font-family-thin;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-justify {
  text-align: justify;
}

.main-container {
  padding: 1vw;
  width: 100%;
  height: 95%;
  .inner-header-container {
    width: 100%;
    height: 6%;
    border-radius: 7px;
    padding-left: 1%;
    display: flex;
    align-items: center;
    @include shadow-large;
    .left-part {
      display: flex;
      height: 100%;
      align-items: center;
      width: 90%;
      .close-icon {
        background-image: url("assets/icons/close.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 16px;
        cursor: pointer;
      }
      .inner-title {
        font-size: 1.25rem;
        margin-left: 1%;
        text-transform: uppercase;
      }
    }
  }
  .close-icon {
    background-image: url("assets/icons/close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 16px;
    cursor: pointer;
  }
  .upload-icon {
    background-image: url("assets/icons/upload.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 16px;
    cursor: pointer;
  }
  .filter-icon {
    background-image: url("assets/icons/filter-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 16px;
    cursor: pointer;
    &.active {
      background-image: url("assets/icons/filter-icon-active.svg");
    }
  }
  .plus-icon {
    background-image: url("assets/icons/plus-icon-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 16px;
    cursor: pointer;
  }
  .edit-icon {
    background-image: url("assets/icons/edit-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 16px;
    cursor: pointer;
    &.blue {
      background-image: url("assets/icons/edit-icon-blue.svg");
      width: 20px;
      height: 20px;
    }
  }
  .date-icon {
    background-image: url("assets/icons/calendar.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 1%;
  }
  .plus-icon {
    background-image: url("assets/icons/plus-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    cursor: pointer;
    &.black {
      background-image: url("assets/icons/plus-black.svg");
    }
  }
  .download-icon {
    background-image: url("assets/icons/download.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .export-icon {
    background-image: url("assets/icons/download.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .export-icon-grid {
    background-image: url("./assets/icons/export.svg");
    background-size: contain;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .anvil-content {
    -webkit-animation: anvil 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #0077ff;
    margin: 10px 0px;
    border-radius: 7px;
    &.vertical {
      height: 100%;
      width: 1px;
      margin: 0px 10px;
    }
    &.black {
      background-color: #313741;
    }
  }
  .body-container {
    height: 91%;
    margin-top: 0.5%;
    border-radius: 7px;
    padding: 1vw;
    @include shadow-large;
    .inner-title {
      font-size: 1.25rem;
      margin-left: 1%;
      text-transform: uppercase;
    }
    &.body-map {
      padding: 0px;
      box-shadow: none;
      margin-top: 0%;
    }
    &.row {
      display: flex;
    }
  }
}
.inner-title {
  font-size: 1.25rem;
  margin-left: 1%;
  text-transform: uppercase;
}
.flex-container {
  display: flex;
  &.full-width {
    width: 100%;
  }
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}
.pointer {
  cursor: pointer;
}
.delete-icon {
  background-image: url("assets/icons/trash.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.ad-icon {
  background-image: url("./assets/icons/open-ad.svg") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.page-arrow-icon {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("./assets/icons/arrow-down.svg");
  min-width: 16px;
  height: 1rem;
  width: 1rem;
  margin-left: 6px;
}
.indicator {
  width: 1%;
  background-color: white;
  border-radius: 7px;
  &.gray {
    background-color: #d2e0fa;
  }
  &.green {
    background-color: #00d297;
    margin: 20px 0px;
    height: 35px;
  }
  &.blue {
    background-color: #002c78;
  }
  &.purple {
    background-color: #9325ff;
  }
  &.black {
    background-color: black;
  }
  &.main {
    background-color: $main-color;
  }
}
.popup-content{
  -webkit-animation: anvil 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
}
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
/*end BASE*/
